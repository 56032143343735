<template>
  <Modal class="news-visor default" ref="modal" :settings="{ opened: true, outClose: true }" @close="newsClose">
    <div class="news-visor-title" slot="header">
      <p>My Space 1A</p>
    </div>
    <div class="news-visor-content" v-if="news && news._id">
      <div class="news-visor-title __content">{{ news.titleNews || "Sin titulo" }}</div>
      <div class="news-visor-comments">
        <Spacer num="2" />
        <Comments :newId="news._id" :closeModal="newsClose" />
      </div>
    </div>
    <div class="news-visor-error" v-else-if="error">
      <ErrorCard :message="error" />
    </div>
    <div class="news-visor-loading" v-else>
      <iconic name="dots_loading" />
    </div>
  </Modal>
</template>

<script>
import "./NewsVisor.scss";
export default {
  components: {
    Comments: () => import("@/components/news/comments/Comments.vue"),
  },
  data: function() {
    return {
      error: null,
    };
  },
  methods: {
    newsClose: function() {
      this.$store.state.news.NewsCommentsVisor = null;
    },
  },
  computed: {
    news: function() {
      return this.$store.state.news.NewsCommentsVisor || {};
    },
  },
};
</script>
